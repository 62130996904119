











































































import { ModuleManagerState, NetworkModulesManager } from '@/includes/logic/Modules/ModulesManager'
import ModuleBuilder from '@/includes/logic/Modules/Builders/ModuleBuilder'
import ModuleCard from '@/components/Modules/components/ModuleCard.vue'
import ModuleActionsMixin from '@/includes/logic/Modules/mixins/ModuleActionsMixin'
import ActiveModulesList from '@/components/Modules/components/ActiveModulesList.vue'
import BaseModule from '@/includes/logic/Modules/models/BaseModule'
import ModulesRoutesHelperMixin from "@/includes/logic/Modules/mixins/ModulesRoutesHelperMixin";
import { BaseNetworkData } from "@/includes/types/networks";
import NetworksHelperMixin from "@/includes/logic/Networks/NetworksHelperMixin";
import NetworkChatEntities, { NetworkChatEntitiesData } from "@/components/networks/NetworkChatEntities.vue";
import { inactiveEntitiesChats } from "@/includes/logic/Networks/utils";

import AddEntityCard from 'piramis-base-components/src/components/AddEntityCard.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'

import { Component, Mixins, Watch } from 'vue-property-decorator'

@Component({
  data() {
    return {
      ModuleManagerState
    }
  },
  components: {
    NetworkChatEntities,
    ActiveModulesList,
    ModuleCard,
    PageTitle,
    AddEntityCard,
    Icon
  },
})
export default class ActiveModules extends Mixins(ModuleActionsMixin, ModulesRoutesHelperMixin, NetworksHelperMixin) {
  chatModules: Array<ModuleBuilder> = []

  netModules: NetworkChatEntitiesData = []

  @Watch('$route.params.CHAT_ID')
  onChatChange() {
    this.setChatModules()
  }

  get groupsWithoutModules() {
    if (!this.isChatManagerVm) {
      const network = this.$store.state.networksState.currentNetwork

      if (network) {
        return inactiveEntitiesChats(network, 'group_without_modules')
      }
    }

    return null
  }

  get readonlyNetwork() {
    if (this.isChatManagerVm) {
      return false
    }

    return this.$store.getters.readonlyNetwork
  }

  get hasActions() {
    if (this.$store.state.networksState.currentNetwork) {
      return !this.$store.state.networksState.currentNetwork.read_only
    } else {
      return true
    }
  }

  clearUpdatedModuleGuid() {
    if (ModuleManagerState) {
      ModuleManagerState.updatedModuleGuid = null
    }
  }

  getActiveChatNetworkModulesBuilders(manager: NetworkModulesManager) {
    return manager
      .getActiveModules()
      .reduce(manager.makeBuildersListReducer, [])
  }

  onRemoveModule(module: ModuleBuilder) {
    this.removeModule(module)
      .then(() => {
        this.setChatModules()
      })
  }

  setChatModules() {
    if (ModuleManagerState) {
      this.chatModules = ModuleManagerState
        .getActiveModules()
        .reduce(ModuleManagerState.makeBuildersListReducer, [])
    }
  }

  onModuleSetting(module: BaseModule) {
    this.moduleSetting(module)
      .then((res) => {
        if (res) {
          this.gotoCurrentModule(res.model.guid)
        }
      })
  }

  mounted() {
    this.setChatModules()

    this.$baseTemplate.saveButton.hide()

    const networks = this.$store.state.chatState.chat?.networks
    if (networks && networks.length) {
      networks.forEach((network: BaseNetworkData) => {
        this.netModules.push({
          network,
          data: new NetworkModulesManager({ modules: network.modules })
        })
      })
    }
  }

  destroyed() {
    this.$baseTemplate.saveButton.show()
  }
}
